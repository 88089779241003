import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["output"]

    apply(event) {
        const source = event.target
        const option = source.selectedOptions[0]
        if (option.dataset.specificAbatementCode) {
            this.outputTarget.value = option.dataset.specificAbatementCode
        }
    }

}