import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["table"]

    add(event) {
        event.preventDefault()
        const el = event.currentTarget

        const node = document.createElement("tr")

        fetch(el.href).then((response) => response.text()).then((html) => {
            node.innerHTML = html;
            this.tableTarget.appendChild(node)
        }).then((_) => this.disableTarget(el))
    }

    disableTarget(target) {
        target.classList.add("d-none")
    }
}