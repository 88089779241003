import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output", "progressIndicator"]
    static values = { url: String, filterName: String }

    abortController = null;

    cancelPendingRequest() {
        if (this.abortController) {
            this.abortController.abort()
        }

        this.abortController = new AbortController()
    }

    filter(event) {
        this.cancelPendingRequest()

        const url = new URL(this.urlValue)
        url.searchParams.set(`${this.filterNameValue}[q]`, event.target.value)

        const el1 = this.outputTarget

        const onLoadEndCallback = this.onLoadEnd.bind(this)

        this.onLoadStart()

        fetch(url, { signal: this.abortController.signal }).then((response) => response.text()).then(function (data) {
            el1.innerHTML = data
        }).then(onLoadEndCallback)
    }

    onLoadStart() {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.remove("d-none")
        }
    }

    onLoadEnd(_) {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.add("d-none")
        }
        this.dispatch("loadEnd")
    }
}