import { Controller } from "@hotwired/stimulus"
import { Tooltip } from 'bootstrap';

export default class extends Controller {

    static targets = ["body", "button", "template", "input", "output"]

    connect() {
        const options = { container: this.element, template: this.templateTarget.innerHTML, placement: "left", html: true, sanitize: false, trigger: "manual", title: this.bodyTarget }

        this.tooltip = new Tooltip(this.buttonTarget, options)

    }

    focus(event) {
        this.inputTarget.focus()
    }

    toggle(event) {
        event.preventDefault()

        // The toolip removes the input field from the DOM on hide
        // so we need to mirror the value into an input field outside of the purview of the tooltip (`output`)
        if (this.hasOutputTarget && this.hasInputTarget) {
            this.outputTarget.value = this.inputTarget.value
        }

        this.tooltip.toggle()
    }
}