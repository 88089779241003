import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    submit(event) {
        event.preventDefault()

        const form = new FormData(this.element)
        const url = this.element.action

        fetch(url, { method: "POST", body: form }).then((response) => {
            if (response.redirected) {
                window.location.href = response.url
                return
            }
            return response.text()
        }).then((data) => { if (data != undefined) this.element.outerHTML = data });
    }
}