import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "customerDocumentReference", "sourceDocumentType", "unitPrice",
        "returnedQuantity",
        "input", "output", "billto", "description", "discount", "shipmentMethodCode", "shippingService", "shippingAgentCode", "originatingDocumentDate", "lineNumber", "validationController", "itemDescriptionLabel"];

    static values = { data: Object }

    connect() {
        if (!this.hasDataValue) return;
        
        const line_item = this.dataValue.line_item;

        const documentNumber = line_item.no_bl;

        const document = this.dataValue.documents.find((e) => e.value == documentNumber);
        this.inputTarget.value = documentNumber
        this.outputTarget.value = line_item.code_article

        this.billtoTarget.value = document.bill_to_customer_number
        this.shipmentMethodCodeTarget.value = document.shipment_method_code
        this.shippingAgentCodeTarget.value = document.shipping_agent_code
        this.shippingServiceTarget.value = document.shipping_service
        this.originatingDocumentDateTarget.value = document.originating_document_date
        this.customerDocumentReferenceTarget.value = document.customer_document_reference;
        this.sourceDocumentTypeTarget.value = document.source_document_type

        this.itemDescriptionLabelTarget.innerHTML = line_item.designation;

        this.descriptionTarget.value = line_item.designation;
        this.discountTarget.value = line_item.line_discount_percent;
        this.lineNumberTarget.value = line_item.line_no;
        this.unitPriceTarget.value = line_item.unit_price

        this.validationControllerTarget.dataset.quantityValidatorUrlValue = line_item.validation_url;

        this.returnedQuantityTarget.dataset.shippedQuantity = line_item.quantite;
    }
}