import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["input", "contactSelector", "status", "addItemsLink"]

    connect() {
        const onSelectCallback = this.onSelect.bind(this)

        this.element.addEventListener("autocomplete.change", onSelectCallback)
    }

    onSelect(event) {
        this.contactSelectorTarget.dataset.valueFetcherUrlValue = event.detail.selected.dataset.contactsUrl
        this.addItemsLinkTarget.href = event.detail.selected.dataset.itemsUrl

        this.addItemsLinkTarget.classList.remove("disabled")
    }

    onLoadStart(event) {
        this.statusTarget.classList.remove("d-none")
    }

    onLoadEnd(event) {
        this.statusTarget.classList.add("d-none")
    }


}