import { Controller } from "@hotwired/stimulus"
import { Dispatcher } from 'hotkeys'

export default class extends Controller {
    static values = {
        bindings: { type: Array, default: ["ctrl k", "cmd k"] }
    }
    
    connect() {
        const bindings = new Dispatcher()

        for (let binding of this.bindingsValue) {
            bindings.on(binding, () => this.handleEvent())
        }
    }

    handleEvent() {
        this.element.focus()
    }
}