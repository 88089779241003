import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    setVisibility(event) {
        if (event.detail.isVisible) { 
            this.element.classList.remove("d-none")
        } else {
            this.element.classList.add("d-none")
        }
    }
}