import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static values = { text: String }

    show(event) {
        if (!confirm(this.textValue)) {
            event.preventDefault()
            event.stopImmediatePropagation()
        }
    }
}