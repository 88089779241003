import NestedForm from "stimulus-rails-nested-form"
export default class extends NestedForm {
    add(e) {
        e.preventDefault()

        const html = new DOMParser().parseFromString(this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString()), "text/html").querySelector("div")

        html.dataset.lineItemDataValue = JSON.stringify(e.detail)

        this.targetTarget.insertAdjacentHTML('beforebegin', html.outerHTML)
    }

    remove(event) {
        event.preventDefault()

        if (window.confirm("Vous allez supprimer cette ligne")) {
            super.remove(event)
        }
    }
}