import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
require('flatpickr/dist/flatpickr.min.css')

export default class extends Controller {
    static values = {
        options: Object
    }

    connect() {
        const options = Object.assign({ locale: French, altFormat: 'd-m-Y', altInput: true, dateFormat: 'Y-m-d' }, this.optionsValue);

        flatpickr(this.element, options)
    }
}