import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        exclusions: Array
    }

    applyExclusions() {
        const options = Array.from(this.element.querySelectorAll("input.form-check-input:not(.list-checkbox-all)"));
        const exclusions = this.exclusionsValue

        options.forEach(function (opt) {
            const v = JSON.parse(opt.value)

            const key = `${v.no_bl}-${v.line_no}`;

            if (exclusions.includes(key)) {
                opt.disabled = true
                opt.checked = false
            }
        })
    }

    add() {
        const options = Array.from(this.element.querySelectorAll("input.form-check-input:checked:not(.list-checkbox-all)"));

        const elements = options.map(function (opt) {
            const v = JSON.parse(opt.value)

            return `${v.no_bl}-${v.line_no}`;
        })

        this.exclusionsValue = elements.concat(this.exclusionsValue);
        this.applyExclusions()
    }
}