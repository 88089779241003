import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["value", "phoneNumber", "name", "isNewContact"];

  set_value(event) {
    event.preventDefault()

    const selectedOptionDataset = event.target.querySelector("option:checked").dataset

    if ('email' in selectedOptionDataset)
      this.valueTarget.value = selectedOptionDataset.email

    if ('phonenumber' in selectedOptionDataset)
      this.phoneNumberTarget.value = selectedOptionDataset.phoneNumber

    if ('name' in selectedOptionDataset)
      this.nameTarget.value = selectedOptionDataset.name

    this.isNewContactTarget.value = 'createcontact' in selectedOptionDataset;

    this.fieldsAsReadOnly(event.target.value != "")

    // une fois le contact sélectionné on veut pouvoir afficher les champs
    // sauf si c'est la ligne "sélectionnez un contact"
    const detail = { isVisible: 'displayfields' in selectedOptionDataset } 
    window.dispatchEvent(new CustomEvent('contactSelection', { detail: detail }))
  }

  fieldsAsReadOnly(read_only) {
    if (read_only) {
      this.valueTarget.setAttribute('readonly', 'readonly')
      this.phoneNumberTarget.setAttribute('readonly', 'readonly')
      this.nameTarget.setAttribute('readonly', 'readonly')
    } else {
      this.valueTarget.removeAttribute('readonly')
      this.phoneNumberTarget.removeAttribute('readonly')
      this.nameTarget.removeAttribute('readonly')
    }
  }
}