import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "progressIndicator"]
    static classes = ["hidden"]

    submit(event) {
        event.preventDefault()

        this.displayProgressIndicator()

        event.currentTarget.disabled = "disabled"

        const data = new FormData(this.formTarget);

        if ('mode' in event.params) {
            data.set('save_only', 'true')
        }

        fetch(this.formTarget.action, { method: "POST", body: data })
            .then((response) => response.text())
            .then((html) => this.element.innerHTML = html)
            .finally(() => this.hideProgressIndicator())
    }

    displayProgressIndicator() {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.remove(this.hiddenClass)
        }
    }

    hideProgressIndicator() {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.add(this.hiddenClass)
        }
    }
}