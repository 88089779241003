
import { Controller } from "@hotwired/stimulus"
import { render } from "mustache"

export default class extends Controller {
    static targets = ["output", "template"]
    static values = { view: Object, template: String }

    connect() {
        fetch(this.templateValue).then((response) => response.text()).then((html) => this.template = html)
    }

    compile(event) {
        event.preventDefault()

        const output = render(this.template, this.model)
        this.outputTarget.innerHTML = output
    }

    get model() {
        const data = new FormData(this.element)

        const additionalText = data.get("rejected_request[reason]")

        return Object.assign({}, this.viewValue, {
            additional_text: additionalText
        })
    }
}