import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        keyCodes: {type: Array, default: ["Enter"]}
    }
    connect() {
        this.onKeydown = this.onKeydown.bind(this)

        this.element.addEventListener("keydown", this.onKeydown)
    }

    onKeydown(event) {
        if (this.keyCodesValue.includes(event.key)) {
            event.stopPropagation()
            event.preventDefault()

            this.dispatch("filtered")

            return false
        }
    }

    disconnect() {
        this.element.removeEventListener("keydown", this.onKeydown)
    }
}