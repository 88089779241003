import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
    static targets = ["subject", "body"];
    static values = { url: String }

    compose(event) {
        event.preventDefault()
        var data = new FormData(this.element);

        data.delete("shipping_manifest[text_body]");
        data.delete("shipping_manifest[subject]");
        data.delete("shipping_manifest[documents][]");

        const el = this.bodyTarget;
        const el1 = this.subjectTarget;

        fetch(this.urlValue, { method: "POST", body: data, headers: this.headers }).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        }).then(function (data) {
            el.value = data.body;
            el1.value = data.subject;
        });
    }

    get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
}