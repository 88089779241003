import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    static targets = ['checkboxAll', 'checkbox', 'submit']
    static values = {
        checkedOnInit: { type: Boolean, default: false }
    }

    connect() {
        super.connect()

        if (this.checkedOnInitValue) {
            this.checkboxTargets.filter(c => !c.disabled).forEach(function (opt) {
                opt.click()
            });
        }
    }

    toggle(e) {
        super.toggle(e)

        this.toggleClass(e.target.checked && this.canSubmit)
    }

    get canSubmit() {
        return this.checked.length > 0;
    }

    refresh() {
        super.refresh()

        this.toggleClass(this.canSubmit)
    }

    toggleClass(enable) {
        if (enable) {
            this.submitTargets.forEach((e) => e.classList.remove("disabled"))
        } else {
            this.submitTargets.forEach((e) => e.classList.add("disabled"))
        }
    }

}