import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["shippingAgent", "pickupDate", "output"]
    static values = {
        initials: String
    }

    get date() {
        return this.pickupDateTarget.value;
    }

    get shippingAgent() {
        if (this.shippingAgentTarget.selectedOptions.length > 0) {
            return this.shippingAgentTarget.selectedOptions[0].dataset.code;
        }

        return '';
    }

    get isReady() {
        return this.date != '' && this.shippingAgent != '';
    }

    apply(event) {
        if (!this.isReady) return;

        const value = `${this.initialsValue} ${this.shippingAgent} ${this.date}`

        this.outputTarget.value = value
    }

}