import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap';

export default class extends Controller {
    static values = {
        id: { type: String, default: "lineItemDetailsModal" },
        append: String,
        replaceOuterHtml: { type: Boolean, default: false },
        title: String,
    }
    static targets = ["source"]

    close(event) {
        event.preventDefault()

        this.modal.hide()
    }

    connect() {
        this.detailsModalElement = document.getElementById(this.idValue)
        this.modal = new Modal(this.detailsModalElement)

        const _onShow = this.onShow.bind(this)
        this.detailsModalElement.addEventListener("show.bs.modal", _onShow)
    }

    displayContent(body) {
        this.modal.show();

        if (this.replaceOuterHtmlValue) {
            this.detailsModalElement.querySelector(".modal-content").outerHTML = body;
        } else {
            this.detailsModalElement.querySelector(".modal-body").innerHTML = body;
        }
    }

    onShow(event) {
        if (this.hasTitleValue) {
            const el = this.detailsModalElement.querySelector(".modal-title")
            el.textContent = this.titleValue
        }
    }

    show(event) {
        event.preventDefault()

        const href = this.hasSourceTarget ? this.sourceTarget.href : this.element.href

        if (href == "#") return;

        const onFetchCallback = this.displayContent.bind(this)

        const url = new URL(href)

        if (this.hasAppendValue) {
            Array.from(document.querySelectorAll(this.appendValue)).forEach(function (element) {
                const v = JSON.parse(element.dataset.lineItemDataValue).line_item

                const key = `${v.no_bl}-${v.line_no}`;

                url.searchParams.append("exclude[]", key)
            });

        }

        fetch(url).then((response) => response.text()).then(onFetchCallback)
    }

    handleUpdate(event) {
        this.modal.handleUpdate()
    }
}