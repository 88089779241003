import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["output"]
    
    apply(event) {
        event.preventDefault();

        const value = event.currentTarget.dataset.id;

        this.outputTargets.forEach((el) => el.value = value);
    }
}