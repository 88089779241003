import { Controller } from "@hotwired/stimulus"
import { render } from "mustache"

export default class extends Controller {
    static targets = ["output"]
    static values = { shipping: Object, templateUrl: String }

    connect() {
        fetch(this.templateUrlValue).then((data) => data.text()).then((html) => this.template = html)
    }

    compile(event) {
        event.preventDefault()

        const output = render(this.template, this.model)
        this.outputTarget.innerHTML = output
    }

    get model() {
        const data = new FormData(this.element)

        const shippingText = this.shippingValue[data.get("internal_return_request[customer_pays_for_shipping]")]
        const additionalText = data.get("internal_return_request[additional_email_text]")

        return {
            shipping_and_handling: shippingText,
            additional_text: additionalText
        }
    }

}