import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { with: String }

    connect() {
        this.element.dataset['action'] = 'submit->disable#disableForm'

        if (!this.hasWithValue) {
            this.withValue = "Envoi en cours..."
        }
    }

    disableForm() {
        this.submitButtons.forEach(button => {
            button.disabled = true
            button.value = this.withValue
        })
    }

    get submitButtons() {
        return this.element.querySelectorAll("input[type='submit']")
    }
}