import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["submit"]
    static values = {
        isValid: {type: Boolean, default: false}
    }

    connect() {
        this.onValueChange = this.onValueChange.bind(this)

        this.bindInputs()

        this.toggleSubmit(this.isValidValue)
        this.hash = this.computeHash()
    }

    onValueChange(event) {
        this.toggleSubmit(this.hasChanged)
    }

    bindInputs() {
        const el = this
        Array.from(this.formElements).forEach(function (opt) {
            opt.addEventListener("change", el.onValueChange)
        });
    }

    disconnect() {
        const el = this

        Array.from(this.formElements).forEach(function (opt) {
            opt.removeEventListener("change",el.onValueChange)
        });
    }

    toggleSubmit(active) {
        Array.from(this.submitButtons).forEach(function (opt) {
            if (active) {
                opt.classList.remove("disabled")
            } else {
                opt.classList.add("disabled")
            }
        });
    }

    get formElements() {
        return this.element.querySelectorAll("input,select");
    }

    get hasChanged() {
        return this.hash !== this.computeHash()
    }

    computeHash() {
        const data = new FormData(this.element)
        data.delete("_method")
        data.delete("authenticity_token") // Seems to change when refreshing

        const message = new URLSearchParams(data).toString()
        return message
    }

    get submitButtons() {
        if (this.hasSubmitTarget) {
            return this.submitTargets;
        }
        return this.element.querySelectorAll("input[type=submit], button[type=submit]")
    }
}