import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { signedIn: Boolean, vapidPublicKey: Array, subscriptionPath: String }

    connect() {
        if (this.hasSignedInValue) {
            if (navigator.serviceWorker) {

                const subscribeSuccessCallback = this.subscribeSuccess.bind(this)
                const key = new Uint8Array(this.vapidPublicKeyValue)

                navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
                    .then(function (reg) {
                        console.log('[Page] Service worker registered.');

                        if (reg.pushManager) {
                            reg.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: key,
                            }).then(subscribeSuccessCallback);
                        } else {
                            console.log('[Page] Push subscription not supported.');
                        }
                    });
            }
        }
    }

    subscribeSuccess(subscription) {
        const params = {
            subscription: subscription
        }

        fetch(this.subscriptionPathValue, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
    }
}