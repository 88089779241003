import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "message"]
    static values = { url: String }

    change(_) {
        if (!this.hasUrlValue)
            return;

        if (this.inputTarget.value == "") {
            this.setIsValidState()
            return
        }

        const url = new URL(this.urlValue)
        url.searchParams.append("q", this.inputTarget.value)

        fetch(url).then((response) => response.text()).then(this.onResult.bind(this));
    }

    setIsValidState() {
        this.inputTarget.classList.remove("is-invalid")
    }

    setIsInvalidState(message) {
        this.messageTarget.innerHTML = message;
        this.inputTarget.classList.add("is-invalid");
    }

    onResult(data) {
        if (data == "") {
            this.setIsValidState();
        } else {
            this.setIsInvalidState(data)
        }
    }
}