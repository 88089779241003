import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String };

    connect() {
        if (this.hasUrlValue) {
            this.filter();
        }
    }

    urlValueChanged() {
        this.filter();
    }

    filter() {
        if (this.urlValue != '') {
            this.setProgressStatus(true)
            fetch(this.urlValue).then((response) => response.text()).then((data) => this.setElementBody(data)).then((_) => this.dispatch("loaded", {}));
        }
    }

    setProgressStatus(loading) {
        if (loading) {
            this.element.innerHTML = "<option>Chargement de la liste en cours...</option>"
            this.element.setAttribute("disabled","disabled")
        } else {
            this.element.removeAttribute("disabled")
        }
    }

    setElementBody(data) {
        this.setProgressStatus(false)
        this.element.innerHTML = data
    }
}