import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import NestedForm from "stimulus-rails-nested-form"
import { Autocomplete } from 'stimulus-autocomplete'

window.Stimulus = Application.start()
const context = require.context(".", true, /.+_controller\.js$/)
Stimulus.load(definitionsFromContext(context))

Stimulus.register("nested-form", NestedForm)
Stimulus.register("autocomplete", Autocomplete)