import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    submit(event) {
        event.preventDefault()

        var options = this.element.querySelectorAll("input.form-check-input:checked");

        if (options.length > 0) {
            var url = new URL(event.currentTarget.href);

            Array.from(options).forEach(function (opt) {
                url.searchParams.append("ids[]", opt.value);
            });
            window.location = url;
        }
    }
}