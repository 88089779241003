import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    publish(event) {
        this._postHeightMessage()
    }

    connect() {
        this._postHeightMessage()
    }

    _postHeightMessage() {
        let message = { name: "resize", height: this.element.scrollHeight, width: this.element.scrollWidth };

        window.top.postMessage(message, "*");
    }
}