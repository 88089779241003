import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        assigneeForm: String
    }

    submit(event) {
        event.preventDefault();

        console.log("Here")

        const value = event.currentTarget.dataset.assignee;

        const elements = document.querySelectorAll(this.assigneeFormValue);
        elements.forEach((obj) => {
            obj.value = value;

            const changeEvent = new Event('change');
            obj.dispatchEvent(changeEvent);
        })
    }
}