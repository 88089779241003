import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    apply(event) {
        event.preventDefault();
        this.inputTargets.forEach((el)=>{
            el.value = el.dataset.shippedQuantity;
        });
    }
}