import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String };

    connect() {
        if (this.hasUrlValue) {
            this.load();
        }
    }

    load() {
        fetch(this.urlValue).then((response) => {
            if (response.ok) {
                return response.text()
            }
            return Promise.reject(response);
        }).then((data) => this.element.innerHTML = data).catch((error) => {

        })
    }

}