import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
    static targets = ["progressIndicator"]
    static classes = ["hidden"]

    submit(event) {
        event.preventDefault()
        const url = this.element.getAttribute('action')
        const data = new FormData(this.element);

        this.displayProgressIndicator()

        fetch(url, { method: "POST", body: data, headers: this.headers }).then((response) => {
            if (response.ok) {
                return response.text();
            }
            return Promise.reject(response);
        }).then((html) => this.element.outerHTML = html).finally(() => this.hideProgressIndicator())
    }

    displayProgressIndicator() {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.remove(this.hiddenClass)
        }
    }

    hideProgressIndicator() {
        if (this.hasProgressIndicatorTarget) {
            this.progressIndicatorTarget.classList.add(this.hiddenClass)
        }
    }

    get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
}