import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { name: { type: String, default: "close" } }

    deliver(event) {
        event.preventDefault();

        let message = { name: this.nameValue };

        window.top.postMessage(message, "*");
    }
}