import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        salesHeadersUrl: String
    }
    static targets = ["success", "running"]

    get options() {
        return this.element.querySelectorAll("input.form-check-input:checked:not(.list-checkbox-all):not(:disabled)");
    }

    submit(event) {
        event.preventDefault()

        const count = this.options.length

        if (count > 0) {
            const values = Array.from(this.options).map(function (opt) {
                return JSON.parse(opt.value)
            });

            const documents = new Set(values.map((v) => v.no_bl))

            const onStopRequest = () => {
                this.successTarget.classList.remove("d-none")
                this.runningTarget.classList.add("d-none")

                this.successTarget.innerText = `${count} ligne(s) ajoutée(s)`
            }

            this.fetchDetails([...documents], values).then(onStopRequest)
        }
    }

    resetSelection() {
        Array.from(this.options).forEach(function (el) {
            el.checked = false;
            el.disabled = true;
        });
    }

    startRequest() {
        this.successTarget.classList.add("d-none")
        this.runningTarget.classList.remove("d-none")
    }

    fetchDetails(documents, values) {
        this.startRequest()

        const numbers = documents.join('|')
        const url = new URL(this.salesHeadersUrlValue)
        url.searchParams.set("q", numbers)

        return fetch(url).then((response) => response.json()).then(function (response) {
            values.forEach(function (line_item) {
                const details = {
                    documents: response,
                    line_item: line_item
                }
                window.dispatchEvent(new CustomEvent('itemSelected', { detail: details }))
            });
        })

    }
}