import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap";

export default class extends Controller {

    static values = { text: String }

    connect() {
        this.element.dataset.action = "clipboard#copy:prevent"
    }

    copy(event) {
        event.preventDefault()
        navigator.clipboard.writeText(this.textValue).then(() => this.onFulfilled(), () => this.onRejected());
    }

    onFulfilled() {
        const initialText = this.element.title
        this.element.title = `Adresse ${this.textValue} copiée dans le presse-papier`

        const tooltip = new Tooltip(this.element, { trigger: "manual" });
        const el = this.element

        this.element.addEventListener("shown.bs.tooltip", function () {
            setTimeout(() => {
                el.title = initialText
                tooltip.hide();
            }, 2000)
        })
        tooltip.show()
    }

    onRejected() {

    }
}